import { TEXT, URLS } from "../app/contants";
import {A} from 'hookrouter';
import { useContextConsumer } from "../app/context";
import { useEffect } from "react";

export default function Register(props) {
    const {isMobile} = useContextConsumer();

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className='w-full h-full flex flex-col items-center justify-start'>
            <div className='relative w-full heightMedia bg-black text-white flex items-center justify-center'>
                <div className='w-full md:w-10/12 h-full flex items-center justify-between'>
                    <img alt='seba' src="/assets/images/mobile-hero.png" className='object-fit h-full' />
                </div>
                {
                    isMobile ?
                    <>
                        <div className='absolute top-0 left-0 w-full h-full text-white flex flex-col items-center justify-center pl-40 md:pl-0 text-xl'>
                            <div className='w-1/2 text-center'>{TEXT.REGISTER.INTRO.text}</div>
                            <div className='w-1/2 font-bold text-center text-4xl mt-2'>{TEXT.REGISTER.INTRO.price}</div>
                            <div className='mt-4 w-1/2 text-center'>{TEXT.REGISTER.INTRO.subText}</div>
                            <div className='w-1/2 font-bold text-center text-4xl mt-2'>{TEXT.REGISTER.INTRO.priceExt}</div>
                        </div>
                    </>
                    :
                    <>
                        <div className='absolute top-0 right-0 w-7/12 h-full text-white flex flex-col items-center justify-center text-2xl'>
                            <div className='w-1/2 text-center text-3xl'>{TEXT.REGISTER.INTRO.text}</div>
                            <div className='font-bold text-4xl mt-4'>{TEXT.REGISTER.INTRO.price}</div>
                            <div className='mt-4 w-1/2 text-center text-3xl'>{TEXT.REGISTER.INTRO.subText}</div>
                            <div className='font-bold text-4xl mt-4'>{TEXT.REGISTER.INTRO.priceExt}</div>
                        </div>
                    </>
                }
                <div className='transform absolute bottom-2 left-2 md:bottom-10 md:left-2 w-28 h-28 md:w-40 md:h-40 rounded-full bg-yellow-400 shadow-xl text-black flex flex-col items-center justify-center -rotate-12'>
                    <div className='font-bold text-2xl md:text-5xl text-loveraRed'>30%</div>
                    <div className='font-bold text-2xl md:text-5xl text-loveraRed'>OFF</div>
                    <div className='font-light text-xs'>por esta semana</div>
                </div>
            </div>
            <div className='text-xl font-bold mb-2 mt-4'>
                {TEXT.REGISTER.OVERTEXT}
            </div>
            <A href={URLS.DIRECT.LOGIN} className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>
                {TEXT.REGISTER.ACTION.text}
            </A>
            <div className='font-bold text-xl mx-2'>
                o
            </div>
            <div className='text-2xl md:text-3xl font-bold mb-4'>
                {TEXT.REGISTER.FORM.OVERTEXT}
            </div>
            <div className='px-4 w-full md:w-1/2 mb-4'>
                <input type="text" placeholder='Nombre de usuario' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="password" placeholder='Contraseña' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="text" placeholder='Nombre' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="tel" placeholder='Teléfono' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="tel" placeholder='Documento' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="email" placeholder='Email' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="text" placeholder='País' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="text" placeholder='Ciudad' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <select className='w-full p-4 mb-4 shadow focus:shadow-xl'>
                    <option>Metodo de pago</option>
                </select>
            </div>
            <div className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>
                {TEXT.REGISTER.FORM.ACTION}
            </div>
            <div className='h-8'>

            </div>
        </div>
    );
}