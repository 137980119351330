const { createContext, useContext, useState, useEffect } = require("react");


const globalContext = createContext();

export function GlobalProvider({children}){
    const [isMobile, setIsMobile] = useState(false);
    const [goToContact, setGoToContact] = useState(false);

    useEffect(()=>{
        function handleResize(){
            if (window.innerWidth < 799){
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    },[])

    return (
        <globalContext.Provider value={{isMobile, goToContact, setGoToContact}}>
            {children}
        </globalContext.Provider>
    );
}

export function useContextConsumer(){
    const consumer = useContext(globalContext);

    return consumer;
}