import { A, navigate } from 'hookrouter';
import { useState } from 'react';
import { NAVHEADER, TEXT } from "../app/contants";
import { useContextConsumer } from "../app/context";

export default function NavHeader(props) {
    const {isMobile, setGoToContact} = useContextConsumer();
    const [togle, setTogle] = useState(false);
    
    function styleMenu(data, t){
        return (
            data ?
            `transition duration-100 z-50 bg-black absolute w-full altoMenu flex flex-col items-center justify-center left-0 top-20 ${t ? 'togle-open' : 'togle-close'}`
            :
            `transition duration-100 z-50 bg-black w-full h-full flex items-center justify-center`
        )
    }

    function contactButton(){
        setGoToContact(true);
        clickLink();
    }

    function clickLink(){
        if (isMobile){
            setTogle(false);
        }
    }

    function togleMenu(){
        setTogle(!togle);
    }
    return (
        <nav className='w-screen h-20 bg-black flex items-center justify-between text-white'>
            <div className='w-1/5 styleLogo text-4xl flex items-center justify-center text-loveraRed'>{ !isMobile? TEXT.HEADER.LOGO : TEXT.HEADER.LOGO_MOBILE }</div>
            {
                isMobile ?
                <div className={`w-20 h-20 flex items-center justify-center ${togle ? 'text-loveraRed' : 'text-white'}`} onClick={togleMenu}><i className={ `text-3xl ${NAVHEADER.ACTION.ICON}` }/></div>
                :
                null
            }
            <div className={styleMenu(isMobile, togle)}>
                {
                    NAVHEADER.BUTTONS.map((b,i) => (
                        <A key={i} onClick={(b.contact? contactButton : clickLink)} className={b.className || 'w-full flex items-center justify-center text-2xl hover:text-loveraRed h-full'} href={b.url}>{b.text}</A>
                    ))
                }
            </div>
        </nav>
    );
}