import React from 'react';
import './App.css';
import { useRoutes } from 'hookrouter';
import Home from './views/Home';
import Contacto from './views/Contacto';
import Login from './views/Login';
import Register from './views/Register';
import User from './views/User';
import Video from './views/Video';
import { URLS } from './app/contants';
import { GlobalProvider } from './app/context';
import NavHeader from './components/NavHeader';
import NavFooter from './components/NavFooter';


const routes = {
  [URLS.DIRECT.HOME]: () => <Home/>,
  [URLS.DIRECT.CONTACT]: () => <Contacto/>,
  [URLS.DIRECT.REGISTER]: () => <Register/>,
  [URLS.DIRECT.LOGIN]: () => <Login/>,
  [URLS.DIRECT.USER]: () => <User/>,
  [URLS.DIRECT.VIDEO]: () => <Video/>
};

function App() {
  const router = useRoutes(routes);

  return (
    <GlobalProvider>
      <main className='w-full min-h-screen overflow-x-hidden overflow-y-auto flex flex-col items-center justify-start'>
        <NavHeader/>
        {router}
        <NavFooter/>
      </main>
    </GlobalProvider>
  );
}

export default App;
