import { TEXT, URLS } from "../app/contants";
import { useContextConsumer } from "../app/context";
import { A } from 'hookrouter';
import { useEffect, useRef } from "react";

export default function Home(props) {
    const {isMobile, goToContact,setGoToContact} = useContextConsumer();
    const contact = useRef();
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        if(goToContact){
            contact.current.scrollIntoView(true);
            setGoToContact(false);
        }
    }, [goToContact]);

    return (
        <>
            <div className='relative w-screen altoMenu bg-black overflow-hidden'>
                    <div className=''>
                        <img style={isMobile ? {height:'100vh', objectFit:'cover'} : {height:'110vh'}} className="absolute top-36 md:top-0 md:relative md:scale-100 left-0" src="/assets/images/hero.png" alt="" />
                    </div>
                    <div className='absolute md:right-20 top-2 md:top-0 h-full text-white text-5xl w-full md:w-2/5 flex flex-col items-center justify-start md:justify-center text-center'>
                        <div className='transform styleLogo text-loveraRed mb-0 underline md:-rotate-6 md:mr-72'>{TEXT.HOME.HERO.TITLE}</div>
                        <div className='font-bold text-5xl md:text-6xl mb-2 md:mb-6'>{TEXT.HOME.HERO.SUBTITLE}</div>
                        <div className='w-4/5 text-xl mb-80 md:text-3xl md:mb-12'>{TEXT.HOME.HERO.MESSAGE}</div>
                    </div>
                    <div className='absolute bottom-0 w-full py-10 flex items-center justify-center'>
                    <A href={URLS.DIRECT.REGISTER} className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>{TEXT.HOME.HERO.ACTION}</A>
                    </div>
            </div>
            <div className={`relative w-screen ${isMobile? 'h-full' : 'heightMedia'} bg-white overflow-hidden flex flex-col md:flex-row items-center justify-center`}>
                {TEXT.HOME.DATA.map((d,i)=>(
                    <div key={i} className='w-full flex flex-col items-center justify-center p-4'>
                        <div>
                            <i className={`text-loveraRed text-7xl ${d.icon}`} />
                        </div>
                        <div className='text-lg text-center w-1/2 md:w-4/5'>
                            {d.text}
                        </div>
                    </div>
                ))}
            </div>
            <div className={`relative w-screen ${!isMobile? 'heightMedia':'h-full'} bg-black text-white overflow-hidden flex flex-col-reverse md:flex-row items-center justify-center`}>
                    <div className='w-full flex items-center justify-center h-40'>
                        <A href={URLS.DIRECT.REGISTER} className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>{TEXT.HOME.DATA2.ACTION.text}</A>
                    </div>
                    <div className='text-2xl sm:text-3xl flex flex-col items-center justify-center w-full h-40'>
                        <div className='text-xl md:text-2xl'>{TEXT.HOME.DATA2.INFO[0].text}</div>
                        <div className='font-bold text-loveraRed'>{TEXT.HOME.DATA2.INFO[1].text} <span className='underline'>{TEXT.HOME.DATA2.INFO[1].subText}</span></div>
                    </div>
            </div>
            <div className='w-full h-full md:h-screen flex flex-col md:flex-row items-center justify-center overflow-hidden'>
                <div className={`${isMobile ? 'maskImageVertical' : 'maskImage'} w-full h-screen`}>
                    <img className='w-full h-screen object-cover' src="/assets/images/car.png" alt="" />
                </div>
                <div className='w-full'>
                    {
                        TEXT.HOME.INFO.map((info,i)=>(
                            <div key={i} className='w-full h-full flex items-center justify-center mb-10'>
                                <div className='w-4/5 flex items-center justify-start'>
                                    <div className='dot rounded-full bg-loveraRed mr-2'></div>
                                    <div className='flex flex-col items-center justify-start'>
                                        <div className='text-xl w-full'>
                                            {info.text}
                                        </div>
                                        <div className='text-3xl w-full text-loveraRed styleLogo'>
                                            {info.subtext}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={`relative w-screen ${isMobile? 'h-full' : 'heightMedia'} bg-black text-white overflow-hidden flex flex-col-reverse md:flex-row items-center justify-center`}>
                    <div className='w-full flex h-full py-10 flex-col items-center justify-center h-40'>
                        <div className='text-3xl md:text-4xl font-bold'>
                            {TEXT.HOME.ACCEDO.title}
                        </div>
                        <div className='w-full h-full flex flex-col md:flex-row items-start justify-center my-8'>
                            {
                                TEXT.HOME.ACCEDO.pasos.map((p,i) => (
                                    <>
                                    <div className='w-full h-full flex flex-col items-center justify-start md:mt-4'>
                                        <div className='w-12 h-12 rounded-full bg-loveraRed flex items-center justify-center text-3xl'>{i+1}</div>
                                        <div className='italic text-2xl'>{p.text}</div>
                                        {
                                            p.subText?
                                            <div className='italic text-2xl'>{p.subText}</div>
                                            :
                                            null
                                        }
                                    </div>
                                    {i < (TEXT.HOME.ACCEDO.pasos.length - 1) ? 
                                        <div className="w-full h-full flex items-center justify-center">
                                            <i className={isMobile ? 'text-5xl ri-arrow-down-s-line' : 'text-5xl ri-arrow-right-s-line'}/>
                                        </div> 
                                        : 
                                        null
                                    }
                                    </>
                                ))
                            }
                        </div>
                        <A href={URLS.DIRECT.REGISTER} className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>{TEXT.HOME.DATA2.ACTION.text}</A>
                    </div>
            </div>
            <div className={`relative w-screen ${isMobile? 'h-full' : 'heightMedia'} py-10 bg-white text-black overflow-hidden flex flex-col items-center justify-center`}>
                <div className='text-3xl md:text-4xl font-bold'>
                    {TEXT.HOME.SOBREMI.title}
                </div>
                <div className='w-11/12 flex flex-col-reverse md:flex-row items-center justify-center'>
                    <div className='w-full flex flex-col items-center justify-center'>
                        <div className='w-4/5'>
                            {TEXT.HOME.SOBREMI.text}
                        </div>
                        <div className='w-4/5 flex items-center justify-center text-loveraRed font-bold text-xl mt-4'>
                            <i className='ri-instagram-fill text-3xl'/> seba_lovera_
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-center'>
                        <div className="starMask">
                            <img alt='seba' src="/assets/images/sobremi.png" className='w-80 h-80 object-cover' />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={contact} className={`relative w-screen ${isMobile? 'h-full' : 'heightMedia'} py-10 bg-black text-white overflow-hidden flex flex-col items-center justify-center`}>
                <div className='text-3xl md:text-4xl font-bold  mb-8'>
                    {TEXT.CONTACT.title}
                </div>
                <div className='w-8/12 flex flex-col-reverse md:flex-row items-center justify-between'>
                    {
                        TEXT.CONTACT.OPTIONS.map((o,i) =>(
                            <div className="transform transition duration-100 font-light text-3xl mx-4  my-8 md:my-0 w-20 h-20 rounded-full flex items-center justify-center bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90" key={i}>
                                <i className={o.icon}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}