import { useEffect } from "react";
import { TEXT } from "../app/contants";

export default function Login(props) {

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className='w-full h-full flex flex-col items-center justify-start'>
            <div className='relative w-full heightMedia bg-black text-white flex items-center justify-center'>
                <div className='w-full md:w-10/12 h-full flex items-center justify-between'>
                    <img src="/assets/images/mobile-hero.png" className='object-fit h-full' alt="" />
                </div>
                <div className='absolute top-0 left-5 md:left-40 w-full h-full text-white flex flex-col items-center justify-center pl-32 md:pl-0 md:text-xl'>
                    <div className='w-4/5 md:w-2/5'>{TEXT.LOGIN.MESSAGE}</div>
                </div>
            </div>
            <div className='text-xl font-bold mb-2 mt-4'>
                {TEXT.LOGIN.OVERTEXT}
            </div>
            <div className='text-lg font-bold mb-2 mt-4'>
                {TEXT.LOGIN.SUBTEXT}
            </div>
            <div className='px-4 w-full md:w-1/2 mb-4'>
                <input type="text" placeholder='Nombre de usuario' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
                <input type="password" placeholder='Contraseña' className='w-full p-4 mb-4 shadow focus:shadow-xl' />
            </div>
            <div className='transform transition duration-100 text-2xl md:text-3xl p-4 bg-loveraRed text-white hover:scale-110 cursor-pointer active:bg-white active:text-black active:scale-90'>
                {TEXT.LOGIN.ACTION}
            </div>
            <div className='h-8'>

            </div>
        </div>
    );
}