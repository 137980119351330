export const URLS = {
    DIRECT: {
        HOME: '/',
        CONTACT: '/contact',
        REGISTER: '/register',
        LOGIN: '/login',
        USER: '/user',
        VIDEO: '/video'
    }
}

export const NAVHEADER = {
    ACTION: {
        ICON: 'ri-menu-line'
    },
    BUTTONS: [
        {url:'/', text: 'Home', icon:'fa-home-line'},
        {url:'/', text: 'Contacto', icon:'fa-phone-line', contact:true},
        {url:'/register', text: '¡QUIERO APRENDER!', icon:'fa-arrow-left-line', className:'w-full flex items-center justify-center text-2xl bg-loveraRed hover:text-black h-full active:bg-white active:text-black'}
    ]
}

export const TEXT = {
    HEADER: {
        LOGO: 'Seba Lovera',
        LOGO_MOBILE: 'S L',
    },
    HOME: {
        HERO: {
            TITLE: 'Seba Lovera',
            SUBTITLE: 'CAPACITACIÓN EMPRESARIAL',
            MESSAGE: '¿Queres aprender a gestionar al 100% tu tienda de tatuajes?',
            ACTION: 'INGRESA AL CURSO'
        },
        DATA: [
            {icon:'ri-emotion-2-fill', text:'Te cuento cómo llegar a más clientes y que vuelvan'},
            {icon:'ri-team-fill', text:'Te enseño a formar tu mejor equipo de trabajo'},
            {icon:'ri-line-chart-fill', text:'Te explico las mejores estrategias de Marketing'},
            {icon:'ri-coins-fill', text:'Te guío a administrar y dominar las finanzas de tu tienda'}
        ],
        DATA2: {
            INFO:[
                {text:'¿Estás listo para administrar tu estudio?'},
                {text:'¡ESTE CURSO', subText: 'ES PARA VOS!'}
            ],
            ACTION: { text:'¡QUIERO APRENDER!', url:'/register' }
        },
        INFO:[
            {text: 'Una vez adquirido, vas a tener', subtext: 'acceso ilimitado a todo el curso'},
            {text: 'Suscripción', subtext:'por única vez'},
            {text: 'Modalidad online', subtext:'para que puedas verlo donde quieras'},
            {text: 'Ayuda y soporte', subtext:'desde un grupo de Facebook'},
            {text: 'Examen final cuando el usuario lo requiera',subtext:'con diploma'},
            {text: 'Un video extra de estrategias para usar en Instagram',subtext:'de regalo'}
        ],
        ACCEDO:{
            title:'¿Cómo accedo al curso?',
            pasos: [
                {text:'Registrate'},
                {text:'Crea tu usuario'},
                {text:'Empezá', subText:'sin interrupciones'}
                
            ]
        },
        SOBREMI:{
            title:'Sobre mi',
            text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu lacinia eros. Sed eu consequat mi. Vivamus vestibulum arcu eu interdum condimentum. Fusce lorem velit, condimentum non lorem elementum, ullamcorper vehicula felis. Sed lacinia, neque vitae consequat dapibus, nibh sapien tempus magna, non accumsan quam dui ut ipsum. Etiam tristique.'
        }
    },
    CONTACT: {
        title:'Contacto',
        OPTIONS: [
            {icon:'ri-facebook-fill'},
            {icon:'ri-instagram-fill'},
            {icon:'ri-mail-fill'},
            {icon:'ri-whatsapp-fill'}
        ]
    },
    REGISTER: {
        INTRO:{
            text:'Accede a todo el curso a tan solo',
            price:'$5900.-',
            subText:'o si te encontras en el exterior a',
            priceExt:'u$d35.-'
        },
        OVERTEXT: '¿Ya tenés cuenta?',
        ACTION:{text:'INGRESA'},
        FORM:{
            OVERTEXT:'REGISTRATE',
            ACTION:'REGISTRARME'
        }
    },
    LOGIN:{
        MESSAGE:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu lacinia eros. Sed eu consequat mi.',
        OVERTEXT:'INGRESAR',
        SUBTEXT:'Ingresa con tus datos',
        ACTION:'INGRESAR'
    }
}